import classNames from 'classnames'
import Img from 'gatsby-image'
import LocalizedLink from 'src/components/LocalizedLink'
import React from 'react'

import styles from './styles.module.css'

export default React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(
        styles.blogPostItem,
        props.blogPostItemSize,
        props.mobileStyle
      )}
    >
      <LocalizedLink to={props.path} className={styles.blogPostContainer}>
        <div className={styles.imageContainer}>
          {props.headline && (
            <div className={styles.headline}>{props.headline}</div>
          )}
          <Img
            className={classNames(styles.image, {
              [styles.roundedCorner]: props.headline,
            })}
            fluid={props.imageURL}
            alt="Blog"
          />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.postTitle}>{props.title}</div>
          <div className={styles.postExcerpt}>{props.excerpt}</div>
          <div className={styles.blogPostFooter}>
            <div>{props.postNumber}</div>
            <div className={styles.footerDate}>{props.modifiedDate}</div>
          </div>
        </div>
      </LocalizedLink>
      <div className={styles.arrow} />
    </div>
  )
})
