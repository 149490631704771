import classNames from 'classnames'
import { graphql } from 'gatsby'
import React, { Component } from 'react'
import BlogPostItem from 'src/components/BlogPage/BlogPostItem'
import FeaturedBlogPostItem from 'src/components/BlogPage/FeaturedBlogPostItem'
import Header from 'src/components/Header'
import Layout from 'src/components/Layout'

import styles from './styles.module.css'

class BlogPage extends Component {
  state = {
    shouldUpdateFiller: false,
    lastPostToShow: 1,
    numberOfFillerCard: 0,
    numberOfCardsPerRow: 1,
    lastWidth: undefined,
  }

  blogPostItemContainer = React.createRef()
  blogPostItem = React.createRef()

  componentDidMount() {
    this.updateLayout()
    window.addEventListener('resize', this.updateFiller)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateFiller)
  }

  componentDidUpdate() {
    if (this.state.shouldUpdateFiller) {
      this.updateLayout()
    }
  }

  createFeaturedBlogPost = (featuredBlogPost, blog) => (
    <>
      <FeaturedBlogPostItem
        title={featuredBlogPost.title.value}
        excerpt={featuredBlogPost.excerpt.value}
        path={`/blog/${featuredBlogPost.title.slug}`}
        headline={blog.headline.value}
        modifiedDate={featuredBlogPost.cockpitModified.substring(0, 4)}
        imageURL={featuredBlogPost.image.value.childImageSharp.fluid}
        mobileStyle={styles.hiddenInMobile}
      />
      <BlogPostItem
        title={featuredBlogPost.title.value}
        excerpt={featuredBlogPost.excerpt.value}
        path={`/blog/${featuredBlogPost.title.slug}`}
        headline={blog.headline.value}
        modifiedDate={featuredBlogPost.cockpitModified.substring(0, 4)}
        imageURL={featuredBlogPost.image.value.childImageSharp.fluid}
        blogPostItemSize={styles.blogPostItemSize}
        mobileStyle={styles.mobileFeaturedBlogPost}
      />
    </>
  )

  render() {
    const {
      pageContext,
      data: { blogPosts, blog, featuredBlogPost },
    } = this.props
    const publishedBlogPosts = blogPosts.nodes.filter(
      ({ published }) => !!published.value
    )
    return (
      <Layout pageContext={pageContext}>
        <div className={styles.header}>
          <Header
            title={blog.title.value}
            description={blog.titleDescription.value}
          />
          <div className={styles.subtitleContainer}>
            <div className={styles.title}>{blog.subtitle.value}</div>
            <div className={styles.subtitleDescription}>
              {blog.subtitleDescription.value}
            </div>
          </div>
        </div>
        {featuredBlogPost.featuredBlogPost
          ? this.createFeaturedBlogPost(
              featuredBlogPost.featuredBlogPost.value,
              blog
            )
          : this.createFeaturedBlogPost(blogPosts.nodes[0], blog)}

        <div
          ref={this.blogPostItemContainer}
          className={styles.blogPostItemContainer}
        >
          {blogPosts.nodes
            .filter(({ published }) => !!published.value)
            .filter(({ cockpitId }) =>
              featuredBlogPost.featuredBlogPost
                ? cockpitId !==
                  featuredBlogPost.featuredBlogPost.value.cockpitId
                : cockpitId !== blogPosts.nodes[0].cockpitId
            )
            .concat(Array(this.state.numberOfFillerCard).fill(null))
            .slice(0, this.state.lastPostToShow)
            .map((data, index) => {
              if (data != null) {
                const path = `/blog/${data.title.slug}`
                return (
                  <BlogPostItem
                    title={data.title.value}
                    excerpt={data.excerpt.value}
                    path={path}
                    modifiedDate={data.cockpitModified.substring(0, 4)}
                    postNumber={`${index + 1}/${publishedBlogPosts.length - 1}`}
                    key={data.cockpitId}
                    imageURL={data.image.value.childImageSharp.fluid}
                    blogPostItemSize={styles.blogPostItemSize}
                    ref={index === 0 ? this.blogPostItem : undefined}
                  />
                )
              } else {
                return (
                  <div key={'empty'} className={styles.blogPostItemSize}></div>
                )
              }
            })}
        </div>
        <div className={classNames(styles.buttonContainer)}>
          <button
            onClick={this.showMorePosts}
            className={classNames(styles.button, {
              [styles.hidden]:
                this.state.lastPostToShow >= publishedBlogPosts.length - 1,
            })}
          >
            {blog.loadMore.value}
          </button>
        </div>
      </Layout>
    )
  }

  showMorePosts = () => {
    this.setState({
      lastPostToShow:
        this.state.lastPostToShow + this.state.numberOfCardsPerRow * 2,
    })
  }

  updateFiller = event => {
    this.setState({
      shouldUpdateFiller:
        !!this.state.lastWidth &&
        event.target.outerWidth !== this.state.lastWidth,
      lastWidth: event.target.outerWidth,
    })
  }

  updateLayout = () => {
    if (!this.blogPostItemContainer.current || !this.blogPostItem.current)
      return

    const cardWidth = this.blogPostItem.current.clientWidth
    const containerWidth = this.blogPostItemContainer.current.clientWidth
    const numberOfCardsPerRow = Math.floor(containerWidth / cardWidth)

    const lastPostToShow = 2 * numberOfCardsPerRow

    const totalNumberOfCards = this.props.data.blogPosts.totalCount - 1
    const numberOfFillerCard =
      totalNumberOfCards % numberOfCardsPerRow &&
      numberOfCardsPerRow - (totalNumberOfCards % numberOfCardsPerRow)

    this.setState({
      lastPostToShow,
      numberOfFillerCard,
      numberOfCardsPerRow,
      shouldUpdateFiller: false,
    })
  }
}

export default BlogPage

export const query = graphql`
  query($locale: String) {
    blogPosts: allCockpitBlogPost(
      filter: { lang: { eq: $locale } }
      sort: { fields: cockpitModified, order: DESC }
    ) {
      nodes {
        title {
          value
          slug
        }
        image {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        published {
          value
        }
        excerpt {
          value
        }
        cockpitModified
        cockpitId
      }
      totalCount
    }
    blog: cockpitBlog(lang: { eq: $locale }) {
      title {
        value
      }
      titleDescription {
        value
      }
      subtitle {
        value
      }
      subtitleDescription {
        value
      }
      headline {
        value
      }
      loadMore {
        value
      }
      cockpitId
    }
    featuredBlogPost: cockpitFeaturedBlogPost(lang: { eq: $locale }) {
      featuredBlogPost {
        value {
          cockpitModified
          title {
            value
            slug
          }
          image {
            value {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt {
            value
          }
          cockpitId
        }
      }
    }
  }
`
