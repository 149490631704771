import classNames from 'classnames'
import LocalizedLink from 'src/components/LocalizedLink'
import React from 'react'
import NonStretchedImage from 'src/components/NonStretchedImage'

import styles from './styles.module.css'

const FeaturedBlogPostItem = ({
  excerpt,
  path,
  title,
  headline,
  modifiedDate,
  imageURL,
  mobileStyle,
}) => {
  return (
    <LocalizedLink to={path} className={styles.featuredContainer}>
      <div className={classNames(styles.featuredBlogPostItem, mobileStyle)}>
        <div className={styles.rightPanel}>
          <div className={styles.featuredFooter}>
            <div>{modifiedDate}</div>
          </div>
          <NonStretchedImage
            className={styles.image}
            fluid={imageURL}
            alt="Featured"
          />
        </div>
        <div className={styles.leftPanel}>
          <div className={styles.headline}>{headline}</div>
          <div>
            <div className={styles.postTitle}>{title}</div>
            <div className={styles.postExcerpt}>{excerpt}</div>
          </div>
        </div>
        <div className={styles.arrow} />
      </div>
    </LocalizedLink>
  )
}

export default FeaturedBlogPostItem
